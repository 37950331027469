import React, { useEffect } from 'react'

import PropTypes from 'prop-types'

import { Button } from '@material-ui/core'
import { observer } from 'mobx-react'
import { Link as RouterLink } from 'react-router-dom'

import { ResetPasswordForm, ResetPasswordSuccessMessage } from 'signup/components/organisms'
import { SignUpTemplate } from 'signup/components/templates'

const ResetPasswordPage = ({ location, store }) => {
  const {
    resetPassword,
    isResetPasswordInProgress,
    isResetPasswordSuccessful,
    resetPasswordRemoteErrors,
    resetResetPasswordState,
  } = store

  useEffect(() => {
    return resetResetPasswordState
  }, [])

  // Location to return to after success/cancel = base url. Configure the routing
  // so that the location shows/redirects to the right page (login or login+signup).
  // Also, copy query params so that the user can continue the integration process
  // if they do any funky stuff when on this page (eg. reload).
  const returnLocation = { pathname: '/', search: location.search }

  return (
    <SignUpTemplate
      title="Password reset"
      headerNav={
        <Button
          variant="outlined"
          color="primary"
          component={RouterLink}
          to={returnLocation}
        >
          Log in
        </Button>
      }
    >
      {isResetPasswordSuccessful
        ? <ResetPasswordSuccessMessage returnLocation={returnLocation} />
        : (
          <ResetPasswordForm
            onSubmit={resetPassword}
            disabled={!!isResetPasswordInProgress}
            showSpinner={!!isResetPasswordInProgress}
            remoteErrors={resetPasswordRemoteErrors}
          />
          )}
    </SignUpTemplate>
  )
}

ResetPasswordPage.propTypes = {
  /** Router location injected by BrowserRouter. */
  location: PropTypes.object.isRequired,
  store: PropTypes.shape({
    resetPassword: PropTypes.func.isRequired,
    isResetPasswordInProgress: PropTypes.bool,
    isResetPasswordSuccessful: PropTypes.bool,
    resetPasswordRemoteErrors: PropTypes.object,
    resetResetPasswordState: PropTypes.func.isRequired,
  }).isRequired,
}

export default observer(ResetPasswordPage)
