import React from 'react'

import PropTypes from 'prop-types'

import clsx from 'clsx'

import { Sign } from 'shared/icons'

import style from './AppConnectionSymbol.module.scss'

const AppConnectionSymbol = ({ appIcon: AppIcon, className }) => {
  return (
    <div className={clsx(style.AppConnectionSymbol, className)}>
      <div className={style.IconWrapper}>
        <AppIcon />
      </div>
      <div className={style.Coupler} />
      <div className={style.IconWrapper}>
        <Sign />
      </div>
    </div>
  )
}

AppConnectionSymbol.propTypes = {
  appIcon: PropTypes.elementType,
  className: PropTypes.string,
}

AppConnectionSymbol.defaultProps = {

}

export default AppConnectionSymbol
