import { adaptSignUpConfig } from './adapters.js'

/**
 * Get application config from document
 */
function getAppConfig () {
  const configScript = window.document.getElementById('signup-config')
  return configScript ? adaptSignUpConfig(JSON.parse(configScript.innerHTML)) : {}
}

export {
  getAppConfig,
}
