import { action } from 'mobx'

import { BI_EVENT_PARAMS_MAP } from 'shared/bi'
import { USER_TYPE_RETAILER, USER_TYPE_SUPPLIER } from 'shared/constants/accounts'
import { AppConfigStore, BiLoggerStore } from 'shared/stores'

import { UserProfileStore as RetailerUserProfileStore } from 'retailer/stores'
import { AuthStore } from 'signup/stores'
import { UserProfileStore as SupplierUserProfileStore } from 'supplier/stores'

export class RootStore {
  constructor () {
    this.appConfigStore = new AppConfigStore()
    this.authStore = new AuthStore(this)
    this.biLoggerStore = new BiLoggerStore(this, BI_EVENT_PARAMS_MAP)
    // defer loading user profile store until after we can determine the account type
    this.userProfileStore = undefined
  }

  // FIXME: this class imports UserProfileStore classes from supplier and retailer -
  //  something we considered a no-no up to this point. This should not break anything,
  //  but it would be better to have one base store class with common interface that
  //  serves both account types and all FE apps -> refactoring in serializers and FE.
  getUserProfileStoreClass (type) {
    switch (type) {
      case USER_TYPE_RETAILER: return RetailerUserProfileStore
      case USER_TYPE_SUPPLIER: return SupplierUserProfileStore
      default: throw new Error('Unsupported account type')
    }
  }

  @action.bound
  initUserProfileStore (data) {
    const UserProfileStoreClass = this.getUserProfileStoreClass(data.type)
    this.userProfileStore = new UserProfileStoreClass(this, data)
  }
}
