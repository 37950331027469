import React from 'react'

import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'
import { Helmet } from 'react-helmet'

import { PageFooter, PageHeader } from 'signup/components/molecules'

import style from './SignUpTemplate.module.scss'

const SignUpTemplate = ({ title, headerNav, children }) => {
  return (
    <div className={style.SignUpTemplate}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PageHeader className={style.Header}>
        {headerNav}
      </PageHeader>
      <div className={style.Wrapper}>
        <Typography variant="h2" className={style.Title}>
          {title}
        </Typography>
        <main className={style.Main}>
          {children}
        </main>
      </div>
      <PageFooter />
    </div>
  )
}

SignUpTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  headerNav: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  children: PropTypes.element,
}

SignUpTemplate.defaultProps = {

}

export default SignUpTemplate
