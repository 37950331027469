import React from 'react'

import PropTypes from 'prop-types'

import { Button } from '@material-ui/core'

import { useTranslation } from 'shared/hooks'
import { WixLogo } from 'shared/icons/external'

import style from './ContinueWithWixButton.module.scss'

const ContinueWithWixButton = ({ wixSsoUrl }) => {
  const { t } = useTranslation('signUp')

  return (
    <Button
      className={style.ContinueWithWix}
      variant="outlined"
      size="large"
      href={wixSsoUrl}
      fullWidth
      data-testid="ContinueWithWixButton"
    >
      <WixLogo className={style.WixLogo} /> {t('continueWithWix.button')}
    </Button>
  )
}

ContinueWithWixButton.propTypes = {
  wixSsoUrl: PropTypes.string,
}

export default ContinueWithWixButton
