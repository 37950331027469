import React, { useEffect, useMemo } from 'react'

import PropTypes from 'prop-types'

import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Link,
  TextField,
  Typography,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { addNewStoreModalShown } from '@wix/bi-logger-modalyst/v2'
import { observer } from 'mobx-react'
import { Controller, useForm } from 'react-hook-form'

import { useBiEvents } from 'shared/bi/hooks'
import { LoadingButton } from 'shared/components/atoms'
import { CountrySelect } from 'shared/components/molecules'
import { useTranslation } from 'shared/hooks'
import { getFieldErrorMessage, useRemoteErrors } from 'shared/utils/forms'

import style from './CreateStoreForm.module.scss'

const getValidationScheme = t => ({
  name: {
    required: t('addStoreModal.storeNameField.requiredError'),
    maxLength: {
      value: 120,
      message: t('addStoreModal.storeNameField.maxLengthError', { maxLength: 120 }),
    },
  },
  country: {
    required: t('addStoreModal.countryField.requiredError'),
  },
})

const CreateStoreForm = ({ createStoreStore, onSubmit }) => {
  const { t } = useTranslation('myStores')
  const scheme = useMemo(() => getValidationScheme(t), [t])
  const { correlationId, logBiEvent } = useBiEvents()

  useEffect(() => {
    (async () => {
      await logBiEvent(addNewStoreModalShown({ correlationId, origin: 'sign up' }))
    })()
  }, [])

  const { countryChoices, formDefaults, setFormValid, isSaving, remoteErrors } = createStoreStore

  const { control, handleSubmit, register, errors, formState, setError } = useForm({
    defaultValues: formDefaults,
    mode: 'onChange',
  })
  const { nonFieldErrors } = useRemoteErrors(remoteErrors, setError)

  const { isValid } = formState

  useEffect(() => setFormValid(isValid), [isValid])

  return (
    <Card
      component="form"
      onSubmit={handleSubmit(data => onSubmit(data, { correlationId }))}
      className={style.CreateStoreForm}
      noValidate
    >
      <CardHeader title={t('addStoreModal.title')} />
      <CardContent className={style.CardContent}>
        <TextField
          name="name"
          required disabled={isSaving}
          inputRef={register(scheme.name)}
          error={!!errors.name}
          label={t('addStoreModal.storeNameField.label')}
          placeholder={t('addStoreModal.storeNameField.placeholder')}
          helperText={getFieldErrorMessage(errors, 'name')}
          fullWidth variant="outlined"
          data-testid="CreateStoreForm-nameInput"
        />

        <Controller
          data-testid="createStoreModal_countrySelect"
          name="country"
          control={control}
          label={t('addStoreModal.countryField.label')}
          placeholder={t('addStoreModal.countryField.placeholder')}
          rules={scheme.country}
          required={!!scheme.country.required}
          error={!!errors.country}
          helperText={
              getFieldErrorMessage(errors, 'country') ||
              t('addStoreModal.countryField.tooltip')
            }
          as={
            <CountrySelect countries={countryChoices} />
            }
          variant="outlined" fullWidth
        />

        <Typography>
          {t('addStoreModal.connectExistingStore.label')}{' '}
          <Link
            href={t('addStoreModal.connectExistingStore.URL')}
            target="_blank" rel="noreferrer"
          >
            {t('addStoreModal.connectExistingStore.learnMore.link')}
          </Link>
        </Typography>

        {nonFieldErrors && (
          <Alert severity="error" component={Box} mt={2}>
            {nonFieldErrors?.join('')}
          </Alert>
        )}

      </CardContent>
      <CardActions>
        <LoadingButton
          type="submit"
          variant="contained" color="primary" size="large"
          loading={isSaving}
          disabled={isSaving || !isValid}
          data-testid="CreateStoreForm-nextButton"
        >
          {t('addStoreModal.nextAction.cta')}
        </LoadingButton>
      </CardActions>

      <input type="submit" style={{ display: 'none' }} />
    </Card>
  )
}

CreateStoreForm.propTypes = {
  createStoreStore: PropTypes.shape({
    countryChoices: PropTypes.arrayOf(PropTypes.string).isRequired,
    formDefaults: PropTypes.object.isRequired,
    isSaving: PropTypes.bool,
    remoteErrors: PropTypes.object,
    setFormValid: PropTypes.func.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default observer(CreateStoreForm)
