import React, { useCallback, useState } from 'react'

import PropTypes from 'prop-types'

import { Button, CircularProgress, TextField, Typography } from '@material-ui/core'
import { observer } from 'mobx-react'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useForm } from 'react-hook-form'

import { Alert } from 'shared/components/molecules'
import { EMAIL_REGEX } from 'shared/constants/validation'
import { getFieldErrorMessage, useRemoteErrors } from 'shared/utils/forms'

import style from './ResetPasswordForm.module.scss'

const validationScheme = {
  username: {
    required: 'Email address is required',
    pattern: { value: EMAIL_REGEX, message: 'This is not a valid email address' },
    maxLength: 75,
  },
  recaptchaToken: process.env.REACT_APP_ENVIRONMENT === 'production' ? { required: 'The captcha validation is required' } : undefined,
}

const ResetPasswordForm = props => {
  const { disabled, showSpinner, onSubmit, remoteErrors } = props
  const { errors, handleSubmit, register, setError, setValue } = useForm({ defaultValues: {} })
  const { nonFieldErrors } = useRemoteErrors(remoteErrors, setError)

  register('recaptchaToken', validationScheme.recaptchaToken)
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false)

  const onRecaptchaVerify = useCallback(value => setValue('recaptchaToken', value), [setValue])

  const handleFormSubmit = (event) => {
    handleSubmit(onSubmit)(event)
    setRefreshReCaptcha(r => !r)
  }

  return (
    <div className={style.ResetPasswordForm}>
      <form noValidate onSubmit={handleFormSubmit}>
        <Typography paragraph>
          To recover password enter your registered email.
        </Typography>

        {nonFieldErrors && (
          <Alert severity="error" className={style.NonFieldError}>
            {nonFieldErrors.join(' ')}
          </Alert>
        )}

        <TextField
          variant="outlined"
          fullWidth
          className={style.FormControl}
          disabled={disabled}
          data-testid="resetPasswordUsername"
          label="Email address"
          name="username"
          inputRef={register(validationScheme.username)}
          required={!!validationScheme.username.required}
          error={!!errors.username}
          helperText={getFieldErrorMessage(errors, 'username') || ' '}
        />

        {process.env.REACT_APP_ENVIRONMENT === 'production' && <GoogleReCaptcha onVerify={onRecaptchaVerify} refreshReCaptcha={refreshReCaptcha} />}

        {!!errors?.recaptchaToken && (
          <Alert severity="error" className={style.RecaptchaAlert}>
            Recaptcha token missing or invalid. Try again.
          </Alert>
        )}

        <Button
          color="primary"
          variant="contained"
          size="large"
          type="submit"
          fullWidth
          disabled={disabled}
        >
          {showSpinner ? <CircularProgress size={26} /> : 'Submit'}
        </Button>

      </form>
    </div>

  )
}

ResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  showSpinner: PropTypes.bool,
  remoteErrors: PropTypes.object,
}

ResetPasswordForm.defaultProps = {
  disabled: false,
  showSpinner: false,
}

export default observer(ResetPasswordForm)
