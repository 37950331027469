import React from 'react'

import PropTypes from 'prop-types'

import { Link, Typography } from '@material-ui/core'

import { Instagram, Twitter } from 'shared/icons/external'

import { getAppConfig } from 'signup/config'

import style from './PageFooter.module.scss'

const PolicyLink = ({ url, children }) => (
  <Link href={url} target="_blank" color="inherit" rel="noopener noreferrer">
    {children}
  </Link>
)

PolicyLink.propTypes = {
  url: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
}

const SocialLink = ({ url, icon: Icon }) => (
  <Link href={url} target="_blank" color="inherit" rel="noopener noreferrer">
    <Icon width="1.75em" height="1.75em" />
  </Link>
)

SocialLink.propTypes = {
  url: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
}

const PageFooter = () => {
  const config = getAppConfig()

  return (
    <Typography component="footer" className={style.PageFooter} variant="body2" color="textSecondary">
      <nav className={style.Nav}>
        <PolicyLink url={config.privacyPolicyUrl}>Privacy Policy</PolicyLink>
        <PolicyLink url={config.termsUrl}>Terms of Use</PolicyLink>
      </nav>
      <nav className={style.Social}>
        <SocialLink url="https://twitter.com/modalyst" icon={Twitter} />
        <SocialLink url="https://instagram.com/modalyst" icon={Instagram} />
      </nav>
    </Typography>
  )
}

PageFooter.propTypes = {

}

PageFooter.defaultProps = {

}

export default PageFooter
