import 'scss/signup.scss'

import { configure } from 'shared/config'
import { render } from 'shared/renderReact'
import { initSentry } from 'shared/utils/sentry'

import { App } from './App'
import * as serviceWorker from './config/serviceWorker'

initSentry('signup')
configure({
  i18nNamespaces: ['myStores'],
}).then(() => render(App))

if (module.hot) module.hot.accept()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
