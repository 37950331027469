import React from 'react'

import PropTypes from 'prop-types'

import clsx from 'clsx'

import { ModalystLogo } from 'shared/components/atoms'

import style from './PageHeader.module.scss'

const PageHeader = ({ className, children }) => {
  return (
    <header className={clsx(style.PageHeader, className)}>
      <ModalystLogo withName reverse height={27} className={style.Logo} />
      <nav className={style.Nav}>
        {children}
      </nav>
    </header>
  )
}

PageHeader.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default PageHeader
