import React, { useCallback, useState } from 'react'

import PropTypes from 'prop-types'

import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  Link,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import { Controller, useForm } from 'react-hook-form'
import { Link as RouterLink } from 'react-router-dom'

import { OrDivider, RadioTile } from 'shared/components/atoms'
import { Alert, CountrySelect, PasswordField } from 'shared/components/molecules'
import {
  ONLINE_CHANNEL_OTHER,
  ONLINE_CHANNEL_SHOPIFY,
  ONLINE_CHANNEL_WIX,
  USER_TYPE_RETAILER,
  USER_TYPE_SUPPLIER,
} from 'shared/constants/accounts'
import {
  PASSWORD_VALIDATION_CAPITAL_LETTER_MESSAGE,
  PASSWORD_VALIDATION_CAPITAL_LETTER_REGEX,
  PASSWORD_VALIDATION_DIGIT_MESSAGE,
  PASSWORD_VALIDATION_DIGIT_REGEX,
  PASSWORD_VALIDATION_REQUIRED,
  PASSWORD_VALIDATION_SPECIAL_CHAR_MESSAGE,
  PASSWORD_VALIDATION_SPECIAL_CHAR_REGEX,
} from 'shared/constants/passwordValidation'
import { EMAIL_REGEX } from 'shared/constants/validation'
import { ChannelOther, SignupRetailer, SignupSupplier } from 'shared/icons'
import { Shopify, Wix } from 'shared/icons/external'
import { getFieldErrorMessage, useRemoteErrors } from 'shared/utils/forms'

import { ContinueWithWixButton } from 'signup/components/molecules'
import { useAppConfig } from 'signup/config/hooks'

import style from './SignUpForm.module.scss'

const baseValidationScheme = {
  email: {
    required: 'Email address is required',
    pattern: { value: EMAIL_REGEX, message: 'This is not a valid email address' },
    maxLength: 150,
  },
  password: {
    required: PASSWORD_VALIDATION_REQUIRED,
    maxLength: 128,
    minLength: 8,
    validate: value => {
      if (!PASSWORD_VALIDATION_CAPITAL_LETTER_REGEX.test(value)) { return PASSWORD_VALIDATION_CAPITAL_LETTER_MESSAGE }

      if (!PASSWORD_VALIDATION_SPECIAL_CHAR_REGEX.test(value)) { return PASSWORD_VALIDATION_SPECIAL_CHAR_MESSAGE }

      if (!PASSWORD_VALIDATION_DIGIT_REGEX.test(value)) { return PASSWORD_VALIDATION_DIGIT_MESSAGE }
    }
  },
  businessName: { required: 'Business name is required', maxLength: 100 },
  country: { required: 'Country is required' },
  recaptchaToken: process.env.REACT_APP_ENVIRONMENT === 'production' ? { required: 'The captcha validation is required' } : undefined,
}

const extendedValidationScheme = Object.assign({}, baseValidationScheme, {
  profileType: { required: 'You need to select the profile type', maxLength: 1 },
  onlineChannels: { required: 'You need to choose an integration', maxLength: 120 },
  onlineChannelsOther: { required: false, maxLength: 255 },
})

const getValidationScheme = (extended, profileType) => {
  if (extended) {
    return extendedValidationScheme
  }
  return baseValidationScheme
}

const FIELD_PROPS = {
  variant: 'outlined',
  fullWidth: true,
}

const MAIN_ONLINE_CHANNEL_CHOICES = {
  [USER_TYPE_RETAILER]: [ONLINE_CHANNEL_SHOPIFY, ONLINE_CHANNEL_WIX, ONLINE_CHANNEL_OTHER],
  [USER_TYPE_SUPPLIER]: [ONLINE_CHANNEL_SHOPIFY, ONLINE_CHANNEL_OTHER],
}

const ONLINE_CHANNEL_ICONS = {
  [ONLINE_CHANNEL_SHOPIFY]: Shopify,
  [ONLINE_CHANNEL_WIX]: Wix,
  [ONLINE_CHANNEL_OTHER]: ChannelOther,
}

// Auxiliary components

const ProfileTypeSelect = props => {
  const {
    className,
    fullWidth,
    variant,
    required,
    disabled,
    helperText,
    error,
    ...inputProps
  } = props
  return (
    <FormControl fullWidth={fullWidth} className={className}>
      <RadioGroup row className={style.RadioTileGroup} required={required} {...inputProps}>
        <RadioTile
          value={USER_TYPE_RETAILER}
          disabled={disabled} icon={SignupRetailer} label="Retailer" size="large"
          data-testid="signup-signUpForm-retailerProfileTypeTile"
        />
        <RadioTile
          value={USER_TYPE_SUPPLIER} disabled={disabled} icon={SignupSupplier} label="Supplier" size="large"
          data-testid="signup-signUpForm-supplierProfileTypeTile"
        />
      </RadioGroup>
      <FormHelperText required={required} error={error}>{helperText}</FormHelperText>
    </FormControl>
  )
}

ProfileTypeSelect.propTypes = {
  ...FormControl.propTypes,
  ...RadioGroup.propTypes,
  helperText: PropTypes.string,
}

const OnlineChannelSelect = props => {
  const {
    profileType,
    className,
    fullWidth,
    variant,
    required,
    disabled,
    helperText,
    error,
    ...inputProps
  } = props
  return (
    <FormControl fullWidth={fullWidth} className={className}>
      <FormLabel required={required} error={error} focused={false} className={style.RadioTileGroupLabel}>
        Where have you created your e-commerce store?
      </FormLabel>
      <RadioGroup row className={style.RadioTileGroup} required={required} {...inputProps}>
        {MAIN_ONLINE_CHANNEL_CHOICES[profileType].map(name => (
          <RadioTile
            key={name}
            value={name}
            data-testid={`signup-signUpForm-${name}OnlineChannelTile`}
            disabled={disabled}
            icon={ONLINE_CHANNEL_ICONS[name]}
            label={name}
            size="small"
          />
        ))}
      </RadioGroup>
      <FormHelperText required={required} error={error}>{helperText}</FormHelperText>
    </FormControl>
  )
}

OnlineChannelSelect.propTypes = {
  ...FormControl.propTypes,
  ...RadioGroup.propTypes,
  profileType: PropTypes.oneOf([USER_TYPE_RETAILER, USER_TYPE_SUPPLIER]),
  helperText: PropTypes.string,
}

const OtherOnlineChannelsSelect = React.forwardRef((props, ref) => {
  const {
    className,
    profileType,
    options,
    variant,
    fullWidth,
    required,
    error,
    helperText,
    inputRef,
    value,
    onChange,
    ...rest
  } = props

  const label = 'Select your platform (optional)'
  return (
    <Autocomplete
      ref={ref}
      options={options}
      freeSolo
      onInputChange={(_, value) => onChange(value)}
      value={value || null}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          ref={inputRef}
          label={label}
          variant={variant}
          required={required}
          error={error}
          helperText={helperText}
        />
      )}
      {...rest}
    />
  )
})

OtherOnlineChannelsSelect.propTypes = {
  ...FormControl.propTypes,
  ...Select.propTypes,
  profileType: PropTypes.oneOf([USER_TYPE_RETAILER, USER_TYPE_SUPPLIER]),
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
}

const SignUpForm = props => {
  const { extended, disabled, showSpinner, remoteErrors, onSubmit, signInLocation } = props
  const config = useAppConfig()

  const defaultValues = {
    profileType: '',
    country: 'US',
    onlineChannels: '',
    onlineChannelsOther: '',
  }
  const { control, errors, handleSubmit, register, setError, setValue, watch } = useForm({ defaultValues })
  const { nonFieldErrors } = useRemoteErrors(remoteErrors, setError)

  const { profileType, onlineChannels } = watch()
  const validationScheme = getValidationScheme(extended, profileType)

  const fieldProps = Object.assign({}, FIELD_PROPS, { disabled })

  // if the user has switched profile type, ensure the previous, now invalid selection for online channel does not stick
  if (profileType && onlineChannels && !MAIN_ONLINE_CHANNEL_CHOICES[profileType].includes(onlineChannels)) {
    setValue('onlineChannels', null, { shouldValidate: false })
  }

  register('recaptchaToken', validationScheme.recaptchaToken)
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false)

  const onRecaptchaVerify = useCallback(value => setValue('recaptchaToken', value), [setValue])

  const handleFormSubmit = (event) => {
    handleSubmit(onSubmit)(event)
    setRefreshReCaptcha(r => !r)
  }

  return (
    <>
      {
        config.wixSsoUrl && (
          <>
            <ContinueWithWixButton wixSsoUrl={config.wixSsoUrl} />
            <Box my={2}><OrDivider alwaysHorizontal /></Box>
          </>
        )
      }
      <div className={style.SignUpForm}>
        <form noValidate onSubmit={handleFormSubmit}>

          {nonFieldErrors && (
            <Alert severity="error" className={style.NonFieldError}>
              {nonFieldErrors.join(' ')}
            </Alert>
          )}

          {extended && (
            <Controller
              {...fieldProps}
              data-testid="signup-signUpForm-profileTypeInput"
              name="profileType"
              control={control}
              rules={validationScheme.profileType}
              required={!!validationScheme.profileType?.required}
              error={!!errors.profileType}
              helperText={getFieldErrorMessage(errors, 'profileType') || ' '}
              as={<ProfileTypeSelect />}
            />
          )}

          <TextField
            {...fieldProps}
            data-testid="signup-signUpForm-emailInput"
            label="Email address"
            name="email"
            autoComplete="username"
            inputRef={register(validationScheme.email)}
            required={!!validationScheme.email.required}
            error={!!errors.email}
            helperText={getFieldErrorMessage(errors, 'email') || ' '}
          />
          <PasswordField
            {...fieldProps}
            data-testid="signup-signUpForm-passwordInput"
            name="password"
            autoComplete="new-password"
            inputRef={register(validationScheme.password)}
            required={!!validationScheme.password.required}
            error={!!errors.password}
            helperText={getFieldErrorMessage(errors, 'password') || ' '}
          />
          <TextField
            {...fieldProps}
            data-testid="signup-signUpForm-businessNameInput"
            label="Business name"
            name="businessName"
            inputRef={register(validationScheme.businessName)}
            required={!!validationScheme.businessName.required}
            error={!!errors.businessName}
            helperText={getFieldErrorMessage(errors, 'businessName') || ' '}
          />
          <Controller
            {...fieldProps}
            data-testid="signup-signUpForm-countryInput"
            name="country"
            control={control}
            label={profileType === USER_TYPE_SUPPLIER
              ? 'Where is your inventory located?'
              : 'Where are most of your customers located?'}
            rules={validationScheme.country}
            required={!!validationScheme.country.required}
            error={!!errors.country}
            helperText={getFieldErrorMessage(errors, 'country') || (
              <>
                If your country is not included in the list, please refer to{' '}
                <Link
                  href="https://support.modalyst.co/en/articles/5813918-why-is-my-country-not-available-during-registration"
                  target="_blank" rel="noopener noreferrer"
                >
                  our help center
                </Link>.
              </>
            )}
            as={
              <CountrySelect
                className={style.CountrySelect}
                countries={profileType === USER_TYPE_SUPPLIER ? config.supplierCountries : config.retailerCountries}
              />
            }
          />
          {profileType && (
            <Controller
              {...fieldProps}
              data-testid="signup-signUpForm-onlineChannelsOtherInput"
              name="onlineChannels"
              control={control}
              rules={validationScheme.onlineChannels}
              required={!!validationScheme.onlineChannels?.required}
              error={!!errors.onlineChannels}
              helperText={getFieldErrorMessage(errors, 'onlineChannels') || ' '}
              as={<OnlineChannelSelect profileType={profileType} />}
            />
          )}
          {onlineChannels === ONLINE_CHANNEL_OTHER && (
            <Controller
              {...fieldProps}
              name="onlineChannelsOther"
              data-testid="signup-signUpForm-onlineChannelsOtherInput"
              control={control}
              rules={validationScheme.onlineChannelsOther}
              required={!!validationScheme.onlineChannelsOther?.required}
              error={!!errors.onlineChannelsOther}
              helperText={getFieldErrorMessage(errors, 'onlineChannelsOther') || ' '}
              as={<OtherOnlineChannelsSelect profileType={profileType} options={config.onlineChannelChoices} />}
            />
          )}

          {process.env.REACT_APP_ENVIRONMENT === 'production' && <GoogleReCaptcha onVerify={onRecaptchaVerify} refreshReCaptcha={refreshReCaptcha} />}

          {!!errors?.recaptchaToken && (
            <Alert severity="error" className={style.RecaptchaAlert}>
              Recaptcha token missing or invalid. Try again.
            </Alert>
          )}

          <Button
            type="submit"
            data-testid="signup-signUpForm-SubmitButton"
            color="primary"
            variant="contained"
            size="large"
            fullWidth
            disabled={disabled}
          >
            {showSpinner ? <CircularProgress size={26} /> : 'Sign Up'}
          </Button>
        </form>

        {signInLocation && (
          <Typography variant="body2" color="textSecondary" className={style.SignInPrompt}>
            Already have an account?{' '}
            <Link component={RouterLink} to={signInLocation} className={clsx(disabled && style.DisabledLink)}>
              Log In
            </Link>
          </Typography>
        )}

        <Typography variant="body2" color="textSecondary" className={style.Legals}>
          {'By completing the signup, you are agreeing to our '}
          <Link href={config.termsUrl} target="_blank" rel="noopener noreferrer">Terms of Service</Link>
          {' and '}
          <Link href={config.privacyPolicyUrl} target="_blank" rel="noopener noreferrer">Privacy Policy</Link>.
          This platform best performs on Safari, Firefox, and Chrome. Please do not use Internet Explorer.
        </Typography>
      </div>
    </>
  )
}

SignUpForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  extended: PropTypes.bool,
  disabled: PropTypes.bool,
  showSpinner: PropTypes.bool,
  remoteErrors: PropTypes.object,
  signInLocation: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

SignUpForm.defaultProps = {
  extended: false,
  disabled: false,
  showSpinner: false,
  showSignInLink: false,
}

export default observer(SignUpForm)
