import React from 'react'

import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'
import { observer } from 'mobx-react'

import { AuthorizationCheckWarning } from 'signup/components/organisms'
import { ConnectAppTemplate } from 'signup/components/templates'

/**
 * TODO: display remote errors
 */
const AuthorizationCheckPage = ({ store }) => {
  return (
    <ConnectAppTemplate
      title="Connect your store with Modalyst"
      integrationType={store.storeIntegration.integrationType}
    >
      <AuthorizationCheckWarning />
      <Typography variant="body2" color="textSecondary">
        You are signed in as {store.username}.
      </Typography>
    </ConnectAppTemplate>
  )
}

AuthorizationCheckPage.propTypes = {
  store: PropTypes.shape({
    username: PropTypes.string.isRequired,
    storeIntegration: PropTypes.shape({
      integrationType: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default observer(AuthorizationCheckPage)
