import { useContext } from 'react'

import appConfigContext from './context'

function useAppConfig () {
  return useContext(appConfigContext)
}

export {
  useAppConfig,
}
