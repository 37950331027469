import React from 'react'

import PropTypes from 'prop-types'

import { observer } from 'mobx-react'

import { FormHeader } from 'signup/components/molecules'
import { SignInForm, SignUpForm } from 'signup/components/organisms'
import { ConnectAppTemplate } from 'signup/components/templates'
import { PATH_RESET_PASSWORD } from 'signup/constants'

const SignUpOrSignInPage = ({ location, store }) => {
  const {
    signIn,
    signUp,
    isAuthInProgress,
    isSignUpInProgress,
    isSignInInProgress,
    signUpRemoteErrors,
    signInRemoteErrors,
    storeIntegration,
  } = store
  const { integrationType } = storeIntegration

  return (
    <ConnectAppTemplate
      title="Connect your store with Modalyst"
      integrationType={integrationType}
      leftColContent={(
        <>
          <FormHeader
            title="Create an account"
            subTitle="Sign up for Modalyst to start sourcing awesome products for your store."
            color="primary"
            mb={4}
          />
          <SignUpForm
            onSubmit={signUp}
            disabled={!!isAuthInProgress}
            showSpinner={!!isSignUpInProgress}
            remoteErrors={signUpRemoteErrors}
          />
        </>
      )}
      rightColContent={(
        <>
          <FormHeader
            title="Log in"
            subTitle={<><strong>Already registered?</strong><br />Simply log in to integrate Modalyst.</>}
            color="textSecondary"
            mb={4}
          />
          <SignInForm
            primary={false}
            onSubmit={signIn}
            disabled={!!isAuthInProgress}
            showSpinner={!!isSignInInProgress}
            remoteErrors={signInRemoteErrors}
            resetPasswordLocation={{ pathname: PATH_RESET_PASSWORD, search: location.search }}
          />
        </>
      )}
    />
  )
}

SignUpOrSignInPage.propTypes = {
  location: PropTypes.object.isRequired,
  store: PropTypes.shape({
    signIn: PropTypes.func.isRequired,
    signUp: PropTypes.func.isRequired,
    isAuthInProgress: PropTypes.bool,
    isSignUpInProgress: PropTypes.bool,
    isSignInInProgress: PropTypes.bool,
    signUpRemoteErrors: PropTypes.object,
    signInRemoteErrors: PropTypes.object,
    storeIntegration: PropTypes.shape({
      integrationType: PropTypes.string.isRequired,
    })
  }).isRequired,
}

export default observer(SignUpOrSignInPage)
