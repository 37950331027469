/* eslint camelcase: 0 */

import { keysToCamelCase, keysToSnakeCase } from 'shared/utils/api'

const adaptLoginRequest = data => keysToSnakeCase(data)

const adaptLoginResponse = data => keysToCamelCase(data)

const adaptLogoutRequest = data => data

const adaptLogoutResponse = adaptLoginResponse

const adaptLogoutErrors = ({ non_field_errors, ...data }) => ({
  nonFieldErrors: non_field_errors,
  ...data,
})

const adaptLoginErrors = ({ non_field_errors, ...data }) => ({
  nonFieldErrors: non_field_errors,
  ...data,
})

const adaptRegisterRequest = data => ({
  email: data.email,
  password: data.password,
  business_name: data.businessName,
  country: data.country,
  recaptcha_token: data.recaptchaToken,
  profile_type: data.profileType,
  online_channels: data.onlineChannels,
  online_channels_other: data.onlineChannelsOther,
})

const adaptRegisterResponse = adaptLoginResponse

const adaptRegisterErrors = ({ non_field_errors, ...data }) => ({
  nonFieldErrors: non_field_errors,
  fieldErrors: {
    email: data.email,
    password: data.password,
    businessName: data.business_name,
    country: data.country,
    profileType: data.profile_type,
    onlineChannels: data.online_channels,
    onlineChannelsOther: data.online_channels_other,
  }
})

const adaptResetPasswordRequest = data => keysToSnakeCase(data)

const adaptResetPasswordResponse = data => data

const adaptResetPasswordErrors = ({ non_field_errors, ...data }) => ({
  nonFieldErrors: non_field_errors,
  fieldErrors: data,
})

export {
  adaptLoginRequest,
  adaptLoginResponse,
  adaptLoginErrors,
  adaptLogoutRequest,
  adaptLogoutResponse,
  adaptLogoutErrors,
  adaptRegisterRequest,
  adaptRegisterResponse,
  adaptRegisterErrors,
  adaptResetPasswordRequest,
  adaptResetPasswordResponse,
  adaptResetPasswordErrors,
}
