export const PATH_BASE = '/get-started'
export const PATH_SIGNUP = '/signup/'
export const PATH_SIGNIN = '/login/'
export const PATH_RESET_PASSWORD = '/reset-password/'

export const FLOW_CONNECT = 'connect'
export const FLOW_AUTHENTICATE = 'authenticate'

export const ORIGIN_INTEGRATION = 'integration'

export const AUTHENTICATE_STEP_START = 'authentication_start'

export const AUTO_CONFIRM_COOKIE_KEY = 'auto_confirm_connection'

// authorization flow steps

/** The authorizaton flow has started */
export const CONNECT_STEP_START = 'connect_start'
/** Checking if user is signed in and getting additional data */
export const CONNECT_STEP_SIGNIN_CHECK_IN_PROGRESS = 'connect_signin_check_in_progress'
/** Signin check has finished - user needs to sign in to proceed */
export const CONNECT_STEP_SIGNIN_REQUIRED = 'connect_signin_required'
/** Authorization check is in progress */
export const CONNECT_STEP_CHECK_IN_PROGRESS = 'connect_check_in_progress'
/** Authorization check has finished and it's possible to create the authorization */
export const CONNECT_STEP_CHECK_SUCCESS = 'connect_check_success'
/** "Add new store" form shown to the user */
export const CONNECT_STEP_ADD_STORE_FORM = 'connect_add_store_form'
/** Waiting for user's confirmation to create the authorization after successful check */
export const CONNECT_STEP_WAIT_FOR_CONFIRMATION = 'connect_wait_for_confirmation'
/** Redirecting to app after determining a store is already connected to user's account */
export const CONNECT_STEP_REDIRECT_EXISTING_CONNECTION = 'connect_redirect_existing'
/** Authorization check determined it's impossible to create the authorization  */
export const CONNECT_STEP_IMPOSSIBLE = 'connect_impossible'
/** Authorization creation is in progress */
export const CONNECT_STEP_IN_PROGRESS = 'connect_in_progress'
/** Authorization has been created */
export const CONNECT_STEP_CREATED = 'connect_created'
/** Authorization creation has failed */
export const CONNECT_STEP_FAILED = 'connect_failed'
