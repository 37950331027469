import React, { useEffect } from 'react'

import PropTypes from 'prop-types'

import { Button } from '@material-ui/core'
import { observer } from 'mobx-react'
import { Link as RouterLink } from 'react-router-dom'

import { SignUpForm } from 'signup/components/organisms'
import { SignUpTemplate } from 'signup/components/templates'
import { PATH_SIGNIN } from 'signup/constants'

const SignUpPage = ({ location, store }) => {
  const { signUp, isAuthInProgress, isSignUpInProgress, signUpRemoteErrors, resetSignUpState } = store

  useEffect(function () {
    return resetSignUpState
  }, [])

  // copy search string so we can maintain any GET params passed to the view
  const signInLocation = { pathname: PATH_SIGNIN, search: location.search }

  return (
    <SignUpTemplate
      title="Sign Up"
      headerNav={
        <Button
          component={RouterLink}
          to={signInLocation}
          variant="outlined"
          color="primary"
        >
          Log in
        </Button>
      }
    >
      <SignUpForm
        extended
        onSubmit={signUp}
        disabled={!!isAuthInProgress}
        showSpinner={!!isSignUpInProgress}
        remoteErrors={signUpRemoteErrors}
        signInLocation={signInLocation}
      />
    </SignUpTemplate>
  )
}

SignUpPage.propTypes = {
  location: PropTypes.object.isRequired,
  store: PropTypes.shape({
    signUp: PropTypes.func.isRequired,
    isAuthInProgress: PropTypes.bool,
    isSignUpInProgress: PropTypes.bool,
    signUpRemoteErrors: PropTypes.object,
    resetSignUpState: PropTypes.func.isRequired,
  })
}

export default observer(SignUpPage)
