import React from 'react'

import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'
import { Helmet } from 'react-helmet'

import { OrDivider } from 'shared/components/atoms'
import { integrationIcons } from 'shared/constants/integrations'

import { AppConnectionSymbol } from 'signup/components/atoms'
import { PageFooter, PageHeader } from 'signup/components/molecules'

import style from './ConnectAppTemplate.module.scss'

const ConnectAppTemplate = ({
  integrationType,
  title,
  children,
  leftColContent,
  rightColContent,
}) => {
  return (
    <div className={style.ConnectAppTemplate}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PageHeader className={style.Header} />
      <div className={style.Wrapper}>
        <>
          <div className={style.BeforeTitle}>
            <AppConnectionSymbol appIcon={integrationIcons.get(integrationType)} />
          </div>
          {title && (
            <Typography variant="h2" className={style.Title}>
              {title}
            </Typography>
          )}
        </>
        <main className={style.Main}>
          {(leftColContent || rightColContent) && (
            <div className={style.Columns}>
              <div className={style.Col}>
                {leftColContent}
              </div>
              <OrDivider className={style.ColDivider} switchOrientationOn="lg" />
              <div className={style.Col}>
                {rightColContent}
              </div>
            </div>
          )}
          {children}
        </main>
      </div>
      <PageFooter />
    </div>
  )
}

ConnectAppTemplate.propTypes = {
  integrationType: PropTypes.string.isRequired,
  title: PropTypes.string,
  leftColContent: PropTypes.node,
  rightColContent: PropTypes.node,
  children: PropTypes.node,
}

export default ConnectAppTemplate
