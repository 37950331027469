import React from 'react'

import PropTypes from 'prop-types'

import { observer } from 'mobx-react'

import { CreateStoreForm } from 'signup/components/organisms'
import { ConnectAppTemplate } from 'signup/components/templates'

const AddStorePage = ({ authStore }) => {
  const { storeIntegration, createStoreStore, handleCreateStoreSubmit } = authStore
  const { integrationType } = storeIntegration

  return (
    <ConnectAppTemplate integrationType={integrationType}>
      <CreateStoreForm
        createStoreStore={createStoreStore}
        onSubmit={handleCreateStoreSubmit}
      />
    </ConnectAppTemplate>
  )
}

AddStorePage.propTypes = {
  authStore: PropTypes.shape({
    createStoreStore: PropTypes.object.isRequired,
    storeIntegration: PropTypes.shape({
      integrationType: PropTypes.string.isRequired,
    }).isRequired,
    handleCreateStoreSubmit: PropTypes.func.isRequired,
  })
}

export default observer(AddStorePage)
