import React from 'react'

import PropTypes from 'prop-types'

import { Link, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { observer } from 'mobx-react'

import { CONNECT_STEP_SIGNIN_REQUIRED } from 'signup/constants'
import { useStores } from 'signup/stores/hooks'

import style from './SwitchAccount.module.scss'

const SwitchAccount = ({ className, linkDisabled, renderPrompt }) => {
  const { authStore } = useStores()
  return (
    <div className={clsx(style.SwitchAccount, className)}>
      <Typography variant="body2">
        {renderPrompt(authStore.username)}
      </Typography>
      <Typography variant="body2">
        <Link
          href="#"
          color="primary"
          disabled={linkDisabled}
          onClick={ev => { authStore.signOut(); authStore.setStep(CONNECT_STEP_SIGNIN_REQUIRED); ev.preventDefault() }}
        >
          Sign out of Modalyst to use a different account
        </Link>
      </Typography>
    </div>
  )
}

SwitchAccount.propTypes = {
  className: PropTypes.string,
  linkDisabled: PropTypes.bool,
  renderPrompt: PropTypes.func,
}

SwitchAccount.defaultProps = {
  renderPrompt: username => `You are signed in as ${username}.`,
  linkDisabled: false,
}

export default observer(SwitchAccount)
