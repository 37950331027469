import { useContext } from 'react'

import storesContext from 'shared/stores/context'

function useStores () {
  return useContext(storesContext)
}

export {
  useStores
}
