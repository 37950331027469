import React from 'react'

import PropTypes from 'prop-types'

import { observer } from 'mobx-react'

import { useRemoteErrors } from 'shared/utils/forms'

import { ConfirmConnection } from 'signup/components/organisms'
import { ConnectAppTemplate } from 'signup/components/templates'
import {
  CONNECT_STEP_CHECK_IN_PROGRESS,
  CONNECT_STEP_CHECK_SUCCESS,
  CONNECT_STEP_CREATED,
  CONNECT_STEP_IN_PROGRESS,
  CONNECT_STEP_REDIRECT_EXISTING_CONNECTION,
} from 'signup/constants'

const InstallIntegrationPage = ({ store }) => {
  const {
    requireUserAuthorizationConfirmation,
    confirmAuthorization,
    storeIntegration,
    pageTitle,
    step,
  } = store
  const {
    username,
    integrationType,
    confirmAuthorizationRemoteErrors,
  } = storeIntegration

  const { allErrors } = useRemoteErrors(confirmAuthorizationRemoteErrors)
  const showSpinner = [
    CONNECT_STEP_CHECK_IN_PROGRESS,
    CONNECT_STEP_REDIRECT_EXISTING_CONNECTION,
    CONNECT_STEP_CHECK_SUCCESS,
    CONNECT_STEP_IN_PROGRESS,
    CONNECT_STEP_CREATED,
  ].includes(step)

  return (
    <ConnectAppTemplate
      title={pageTitle}
      integrationType={integrationType}
    >
      <ConfirmConnection
        interactive={requireUserAuthorizationConfirmation}
        username={username}
        integrationType={integrationType}
        onConfirm={confirmAuthorization}
        showSpinner={showSpinner}
        success={step === CONNECT_STEP_CREATED}
        errors={allErrors}
      />
    </ConnectAppTemplate>
  )
}

InstallIntegrationPage.propTypes = {
  store: PropTypes.shape({
    step: PropTypes.oneOf([
      CONNECT_STEP_CHECK_IN_PROGRESS,
      CONNECT_STEP_REDIRECT_EXISTING_CONNECTION,
      CONNECT_STEP_CHECK_SUCCESS,
      CONNECT_STEP_IN_PROGRESS,
      CONNECT_STEP_CREATED,
    ]).isRequired,
    confirmAuthorization: PropTypes.func,
    requireUserAuthorizationConfirmation: PropTypes.bool,
    pageTitle: PropTypes.string.isRequired,
    storeIntegration: PropTypes.shape({
      integrationType: PropTypes.string.isRequired,
      username: PropTypes.string,
      isConfirmAuthorizationInProgress: PropTypes.bool,
      isConfirmAuthorizationSuccessful: PropTypes.bool,
      confirmAuthorizationRemoteErrors: PropTypes.object,
    }).isRequired,
  })
}

export default observer(InstallIntegrationPage)
