import React, { useCallback, useState } from 'react'

import PropTypes from 'prop-types'

import { Box, Button, CircularProgress, Link, TextField, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useForm } from 'react-hook-form'
import { Link as RouterLink } from 'react-router-dom'

import { OrDivider } from 'shared/components/atoms'
import { Alert, PasswordField } from 'shared/components/molecules'
import { EMAIL_REGEX } from 'shared/constants/validation'
import { getFieldErrorMessage, useRemoteErrors } from 'shared/utils/forms'

import { ContinueWithWixButton } from 'signup/components/molecules'
import { useAppConfig } from 'signup/config/hooks'
import { PATH_RESET_PASSWORD } from 'signup/constants'

import style from './SignInForm.module.scss'

const validationScheme = {
  email: {
    required: 'Email address is required',
    pattern: { value: EMAIL_REGEX, message: 'This is not a valid email address' },
    maxLength: 75,
  },
  password: { required: 'Password is required', maxLength: 120 },
  recaptchaToken: process.env.REACT_APP_ENVIRONMENT === 'production' ? { required: 'The captcha validation is required' } : undefined,
}

const FIELD_PROPS = {
  variant: 'outlined',
  fullWidth: true,
  className: style.FormControl,
}

const SignInForm = props => {
  const {
    onSubmit,
    primary,
    remoteErrors,
    disabled,
    showSpinner,
    signUpLocation,
    resetPasswordLocation,
  } = props

  const { wixSsoUrl } = useAppConfig()

  const { errors, handleSubmit, register, setError, setValue } = useForm({ defaultValues: {} })
  const { nonFieldErrors } = useRemoteErrors(remoteErrors, setError)

  const fieldProps = Object.assign({}, FIELD_PROPS, { disabled })

  register('recaptchaToken', validationScheme.recaptchaToken)
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false)

  const onRecaptchaVerify = useCallback(value => setValue('recaptchaToken', value), [setValue])

  const handleFormSubmit = (event) => {
    handleSubmit(onSubmit)(event)
    setRefreshReCaptcha(r => !r)
  }

  return (
    <>
      {
        wixSsoUrl && (
          <>
            <ContinueWithWixButton wixSsoUrl={wixSsoUrl} />
            <Box my={2}><OrDivider alwaysHorizontal /></Box>
          </>
        )
      }
      <div className={style.SignInForm}>
        <form noValidate onSubmit={handleFormSubmit}>

          {nonFieldErrors && (
            <Alert severity="error" className={style.NonFieldError}>
              {nonFieldErrors.join(' ')}
            </Alert>
          )}

          <TextField
            {...fieldProps}
            data-testid="login-logInForm-emailInput"
            label="Email address"
            name="email"
            inputRef={register(validationScheme.email)}
            required={!!validationScheme.email.required}
            error={!!errors.email}
            helperText={getFieldErrorMessage(errors, 'email') || ' '}
          />
          <PasswordField
            {...fieldProps}
            data-testid="login-logInForm-passwordInput"
            name="password"
            autoComplete="current-password"
            inputRef={register(validationScheme.password)}
            required={!!validationScheme.password.required}
            error={!!errors.password}
            helperText={getFieldErrorMessage(errors, 'password') || ' '}
          />

          <div className={style.ResetPassword}>
            <Link component={RouterLink} to={resetPasswordLocation} className={clsx(disabled && style.DisabledLink)}>
              I forgot my password
            </Link>
          </div>

          {process.env.REACT_APP_ENVIRONMENT === 'production' && <GoogleReCaptcha onVerify={onRecaptchaVerify} refreshReCaptcha={refreshReCaptcha} />}

          {!!errors?.recaptchaToken && (
            <Alert severity="error" className={style.RecaptchaAlert}>
              Recaptcha token missing or invalid. Try again.
            </Alert>
          )}

          <Button
            color="primary"
            variant={primary ? 'contained' : 'outlined'}
            size="large"
            type="submit"
            data-testid="login-logInForm-SubmitButton"
            fullWidth
            disabled={disabled}
          >
            {showSpinner ? <CircularProgress size={26} /> : 'Sign In'}
          </Button>
        </form>

        {signUpLocation && (
          <Typography className={style.SignUpPrompt} variant="body2" color="textSecondary">
            Don't have an account?{' '}
            <Link component={RouterLink} to={signUpLocation} className={clsx(disabled && style.DisabledLink)}>
              Create an account
            </Link>
          </Typography>
        )}

      </div>
    </>
  )
}

SignInForm.propTypes = {
  primary: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  showSpinner: PropTypes.bool,
  remoteErrors: PropTypes.object,
  signUpLocation: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  resetPasswordLocation: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

SignInForm.defaultProps = {
  primary: true,
  disabled: false,
  showSpinner: false,
  resetPasswordLocation: PATH_RESET_PASSWORD,
}

export default observer(SignInForm)
