import React from 'react'

import { StylesProvider } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import { ErrorBoundary } from '@sentry/react'
import { observer } from 'mobx-react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { Helmet } from 'react-helmet'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import { Redirect, RouteTracker, FullPageSpinner } from 'shared/components/atoms'
import StoresContext from 'shared/stores/context'

import { useAppConfig } from 'signup/config/hooks'
import {
  PATH_SIGNIN,
  PATH_SIGNUP,
  PATH_RESET_PASSWORD,
  CONNECT_STEP_CHECK_IN_PROGRESS,
  CONNECT_STEP_CHECK_SUCCESS,
  CONNECT_STEP_IMPOSSIBLE,
  CONNECT_STEP_IN_PROGRESS,
  CONNECT_STEP_REDIRECT_EXISTING_CONNECTION,
  CONNECT_STEP_SIGNIN_REQUIRED,
  CONNECT_STEP_WAIT_FOR_CONFIRMATION,
  CONNECT_STEP_START,
  CONNECT_STEP_SIGNIN_CHECK_IN_PROGRESS,
  CONNECT_STEP_CREATED,
  CONNECT_STEP_FAILED,
  CONNECT_STEP_ADD_STORE_FORM,
} from 'signup/constants'
import {
  AuthorizationCheckPage,
  InstallIntegrationPage,
  SignInPage,
  SignUpOrSignInPage,
  SignUpPage,
  ResetPasswordPage,
  AddStorePage,
} from 'signup/pages'
import { RootStore } from 'signup/stores/root'
import { muiTheme } from 'signup/theme'

const root = new RootStore()

const OuterProviders = ({ children }) => { // eslint-disable-line react/prop-types
  return (
    <ThemeProvider theme={muiTheme}>
      <StylesProvider injectFirst>
        <GoogleReCaptchaProvider
          reCaptchaKey="6LfeqI0iAAAAAKyoTQQMSOA5KxdrCIg2_w_odQ8x"
          container={{
            element: 'recaptcha-container',
            parameters: { badge: 'bottomleft' }
          }}
        >
          {children}
        </GoogleReCaptchaProvider>
      </StylesProvider>
    </ThemeProvider>
  )
}

const App = () => {
  const { authStore } = root
  const {
    isAuthenticateFlow,
    isIntegrateFlow,
    storeIntegration,
    isSilent,
    step,
  } = authStore

  // allow the routing base path to be configured from Django, so that we can support
  // varying urls eg. `/login/` (basePath='') vs `/get-started/wix/` (basePath='/get-started')
  const { basePath, gaTrackingId, debug } = useAppConfig()

  return (
    <ErrorBoundary>
      <StoresContext.Provider value={root}>
        <OuterProviders>
          <Helmet titleTemplate="Modalyst - %s" />
          <div id="recaptcha-container" />
          {isAuthenticateFlow && (
            <BrowserRouter basename={basePath}>
              <Switch>
                <Route
                  path={PATH_SIGNUP}
                  render={props => <SignUpPage {...props} store={authStore} />}
                />
                <Route
                  path={PATH_SIGNIN}
                  render={props => <SignInPage {...props} store={authStore} />}
                />
                <Route
                  path={PATH_RESET_PASSWORD}
                  render={props => <ResetPasswordPage {...props} store={authStore} />}
                />
                <Route
                  render={props => <Redirect {...props} pathname={PATH_SIGNIN} />}
                />
              </Switch>
              <RouteTracker trackingId={gaTrackingId} debug={debug} />
            </BrowserRouter>
          )}

          {isIntegrateFlow && (
            <BrowserRouter basename={`${basePath}/${storeIntegration.integrationType}`}>
              {step === CONNECT_STEP_SIGNIN_REQUIRED && (
                <Switch>
                  <Route
                    path={PATH_RESET_PASSWORD}
                    render={props => <ResetPasswordPage {...props} store={authStore} />}
                  />
                  <Route
                    render={props => <SignUpOrSignInPage {...props} store={authStore} />}
                  />
                </Switch>
              )}
              {(
                step === CONNECT_STEP_START ||
                step === CONNECT_STEP_SIGNIN_CHECK_IN_PROGRESS ||
                step === CONNECT_STEP_REDIRECT_EXISTING_CONNECTION ||
                step === CONNECT_STEP_CHECK_IN_PROGRESS ||
                (isSilent && step === CONNECT_STEP_CHECK_SUCCESS) ||
                (isSilent && step === CONNECT_STEP_IN_PROGRESS) ||
                (isSilent && step === CONNECT_STEP_CREATED)
              ) && (
                <FullPageSpinner />
              )}
              {(
                step === CONNECT_STEP_ADD_STORE_FORM
              ) && (
                <AddStorePage authStore={authStore} />
              )}
              {(
                (!isSilent && step === CONNECT_STEP_CHECK_SUCCESS) ||
                (!isSilent && step === CONNECT_STEP_IN_PROGRESS) ||
                (!isSilent && step === CONNECT_STEP_CREATED) ||
                step === CONNECT_STEP_WAIT_FOR_CONFIRMATION ||
                step === CONNECT_STEP_FAILED
              ) && (
                <InstallIntegrationPage store={authStore} />
              )}
              {step === CONNECT_STEP_IMPOSSIBLE && (
                <AuthorizationCheckPage store={authStore} />
              )}
            </BrowserRouter>
          )}
        </OuterProviders>
      </StoresContext.Provider>
    </ErrorBoundary>
  )
}

export default observer(App)
