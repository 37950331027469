import React from 'react'

import PropTypes from 'prop-types'

import { Button, Typography } from '@material-ui/core'
import { observer } from 'mobx-react'

import { SuccessSpinner } from 'shared/components/atoms'
import { Alert } from 'shared/components/molecules'
import { connectableIntegrations, integrationsNames } from 'shared/constants/integrations'

import { SwitchAccount } from 'signup/components/organisms'

import style from './ConfirmConnection.module.scss'

const ConfirmConnection = props => {
  const {
    interactive,
    username,
    integrationType,
    onConfirm,
    showSpinner,
    success,
    errors,
  } = props

  const integrationName = integrationsNames.get(integrationType)

  const handleConfirmClick = event => {
    if (onConfirm) {
      onConfirm()
    }
  }

  return (
    <div className={style.ConfirmConnection}>
      {errors && (
        <Alert severity="error" className={style.RemoteErrors}>
          {errors.join(' ')}
        </Alert>
      )}

      <Typography>
        {interactive
          ? `Confirm that you would like to connect this ${integrationName} store with Modalyst account ${username}.`
          : `We are connecting your ${integrationName} store with your Modalyst account ${username}.`}
      </Typography>

      <SuccessSpinner success={success} hideSpinner={!showSpinner} size={54} my={4} mx="auto" />

      {interactive && (
        <>
          <Button
            size="large"
            color="primary"
            variant="contained"
            disabled={showSpinner || !!errors}
            onClick={handleConfirmClick}
            data-testid="ConfirmConnection-connectButton"
          >
            Connect store
          </Button>

          <SwitchAccount
            className={style.SwitchAccount}
            renderPrompt={username => 'Do you want to connect with another Modalyst account?'}
            disableLink={showSpinner}
          />
        </>
      )}
    </div>
  )
}

ConfirmConnection.propTypes = {
  username: PropTypes.string,
  integrationType: PropTypes.oneOf(connectableIntegrations).isRequired,
  onConfirm: PropTypes.func,
  interactive: PropTypes.bool,
  showSpinner: PropTypes.bool,
  success: PropTypes.bool,
  errors: PropTypes.array,
}

ConfirmConnection.defaultProps = {
  interactive: true,
}

export default observer(ConfirmConnection)
