import React from 'react'

import PropTypes from 'prop-types'

import { Button, Typography, Box } from '@material-ui/core'
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded'
import { Link as RouterLink } from 'react-router-dom'

import style from './ResetPasswordSuccessMessage.module.scss'

/**
 * Component to show after successfully resetting the password
 */
const ResetPasswordSuccessMessage = ({ returnLocation }) => {
  return (
    <div className={style.ResetPasswordSuccessMessage}>
      <Box mb={4} color="success.main">
        <CheckCircleRoundedIcon className={style.SuccessIcon} color="inherit" />
      </Box>

      <Typography paragraph>
        A new password has been sent to your email address.<br />
        You can now use it to log into Modalyst.
      </Typography>

      <Button
        component={RouterLink}
        to={returnLocation}
        variant="outlined"
        color="primary"
        size="large"
        className={style.ActionButton}
      >
        Go to Sign In
      </Button>

    </div>
  )
}

ResetPasswordSuccessMessage.propTypes = {
  returnLocation: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
}

export default ResetPasswordSuccessMessage
