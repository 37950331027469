import { addNewStoreModalNextClicked } from '@wix/bi-logger-modalyst/v2'
import Cookies from 'js-cookie'
import { action, computed, observable } from 'mobx'

import {
  adaptLoginErrors,
  adaptLoginRequest,
  adaptLoginResponse,
  adaptLogoutErrors,
  adaptRegisterErrors,
  adaptRegisterRequest,
  adaptRegisterResponse,
  adaptResetPasswordErrors,
  adaptResetPasswordRequest,
} from 'shared/adapters/accounts'
import { adaptUserProfile } from 'shared/adapters/profiles'
import { login, logout, register, resetPassword } from 'shared/api/accounts'
import { getUserProfile } from 'shared/api/profiles'
import { processApiError } from 'shared/api/utils'
import { USER_TYPE_RETAILER } from 'shared/constants/accounts'
import { CreateStoreStore, StoreIntegrationStore } from 'shared/stores'
import { logger } from 'shared/utils/debug'

import { getAppConfig } from 'signup/config'
import {
  AUTHENTICATE_STEP_START,
  AUTO_CONFIRM_COOKIE_KEY,
  CONNECT_STEP_ADD_STORE_FORM,
  CONNECT_STEP_CHECK_IN_PROGRESS,
  CONNECT_STEP_CREATED,
  CONNECT_STEP_FAILED,
  CONNECT_STEP_IMPOSSIBLE,
  CONNECT_STEP_IN_PROGRESS,
  CONNECT_STEP_REDIRECT_EXISTING_CONNECTION,
  CONNECT_STEP_SIGNIN_CHECK_IN_PROGRESS,
  CONNECT_STEP_SIGNIN_REQUIRED,
  CONNECT_STEP_START,
  CONNECT_STEP_WAIT_FOR_CONFIRMATION,
  FLOW_AUTHENTICATE,
  FLOW_CONNECT,
  ORIGIN_INTEGRATION,
} from 'signup/constants'

/**
 * Store and business logic for use cases related to authenticating the user in the system
 * and authorizing integrations with their account.
 *
 * The app can work in two modes (flows):
 * - INTEGRATION, when the login/signup function serves as a means to an end of
 *   integrating an external store with a Modalyst account
 * - AUTHENTICATION, when the only responsibility of the app is to allow
 *   the user to login or create a new account
 */
class AuthStore {
  config
  flow

  @observable step
  @observable isSilent

  @observable isAuthenticated
  @observable username

  /** Instance of StoreIntegrationStore */
  @observable storeIntegration

  // we store the information about whether the user has authenticated
  // (ie they know what account they are logged in with Modalyst) or not
  // to decide if we should require explicit authorization confirmation
  @observable userHasProvidedCredentials

  @observable userHasProvidedStoreDetails

  @observable isSignUpInProgress
  @observable signUpRemoteErrors

  @observable isSignInInProgress
  @observable signInRemoteErrors

  @observable isSignOutInProgress
  @observable signOutRemoteErrors

  @observable isResetPasswordInProgress
  @observable isResetPasswordSuccessful
  @observable resetPasswordRemoteErrors

  @observable createStoreStore

  @observable signUpDefaults = {
    profileType: undefined,
  }

  /**
   * @param {import('../context').RootStore} root
   */
  constructor (root) {
    this.root = root

    this.createStoreStore = new CreateStoreStore(root)

    this.config = getAppConfig()
    this.flow = this.config.integrationType ? FLOW_CONNECT : FLOW_AUTHENTICATE
    this.isSilent = this.config.isSilentInstallation

    // signup-during-install allows for retailer type account only
    // unless otherwise specified in the app install url
    Object.assign(this.signUpDefaults, {
      profileType: this.config.integrationProfileType || USER_TYPE_RETAILER,
    })

    if (this.flow === FLOW_CONNECT) {
      this.setStep(CONNECT_STEP_START)
      this.storeIntegration = new StoreIntegrationStore({
        integrationType: this.config.integrationType,
        integrationProfileType: this.config.integrationProfileType,
        storeName: this.config.storeName,
        authorizationOrigin: this.config.authorizationOrigin,
        referral: this.config.referral || undefined,
        business: this.config.business || undefined,
      })

      // immediately check for authentication status
      this.checkAuthentication()
    } else {
      this.setStep(AUTHENTICATE_STEP_START)
    }
  }

  get hasAutoConfirmCookie () {
    return !!Cookies.get(AUTO_CONFIRM_COOKIE_KEY)
  }

  @computed get isAuthenticateFlow () {
    return this.flow === FLOW_AUTHENTICATE
  }

  @computed get isIntegrateFlow () {
    return this.flow === FLOW_CONNECT
  }

  @computed get isAuthInProgress () {
    return this.isSignInInProgress || this.isSignUpInProgress || this.isSignOutInProgress
  }

  @computed get requireUserAuthorizationConfirmation () {
    // return false
    return !(
      this.userHasProvidedStoreDetails ||
      this.userHasProvidedCredentials ||
      this.hasAutoConfirmCookie ||
      this.isSilent ||
      this.storeIntegration.authorizationOrigin !== ORIGIN_INTEGRATION
    )
  }

  // general property setters

  @action.bound
  setStep (value) {
    this.step = value
    logger.log(`Now at step ${value} (silent: ${this.isSilent})`)
  }

  @action.bound
  setUsername (value) {
    this.username = value
  }

  @action.bound
  setIsAuthenticated (value) {
    this.isAuthenticated = value
  }

  @action.bound
  setUserHasProvidedCredentials (value) {
    this.userHasProvidedCredentials = value
  }

  @action.bound
  setUserHasProvidedStoreDetails (value) {
    this.userHasProvidedStoreDetails = value
  }

  // signin setters

  @action.bound
  setIsSignInInProgress (value) {
    this.isSignInInProgress = value
  }

  @action.bound
  setSignInRemoteErrors (data) {
    this.signInRemoteErrors = data
  }

  // signup setters

  @action.bound
  setIsSignUpInProgress (value) {
    this.isSignUpInProgress = value
  }

  @action.bound
  setSignUpRemoteErrors (data) {
    this.signUpRemoteErrors = data
  }

  // signout setters

  @action.bound
  setIsSignOutInProgress (value) {
    this.isSignOutInProgress = value
  }

  @action.bound
  setSignOutRemoteErrors (data) {
    this.signOutRemoteErrors = data
  }

  // reset password setters

  @action.bound
  setIsResetPasswordInProgress (value) {
    this.isResetPasswordInProgress = value
  }

  @action.bound
  setResetPasswordRemoteErrors (data) {
    this.resetPasswordRemoteErrors = data
  }

  @action.bound
  setIsResetPasswordSuccessful (value) {
    this.isResetPasswordSuccessful = value
  }

  // actions

  @action.bound
  async signIn (data) {
    if (this.isSignInInProgress) return

    this.setSignInRemoteErrors(undefined)
    this.setIsSignInInProgress(true)

    try {
      const response = await login(adaptLoginRequest(data))

      this.setUserHasProvidedCredentials(true)
      this.setIsAuthenticated(true)

      if (this.isIntegrateFlow) {
        await this.fetchUserData()
        this.setIsSignInInProgress(false)
        this.checkAuthorization()
      } else {
        const { redirectTo } = adaptLoginResponse(response.data)
        const search = new URLSearchParams(window.location.search)
        window.location.href = search.get('next') || redirectTo || '/'
      }
    } catch (error) {
      this.setSignInRemoteErrors(processApiError(error, adaptLoginErrors))
      this.setIsSignInInProgress(false)
    }
  }

  /**
   * Return the state of the sign in to defaults
   */
  @action.bound
  resetSignInState () {
    this.setIsSignInInProgress(undefined)
    this.setSignInRemoteErrors(undefined)
  }

  @action.bound
  signOut (data) {
    if (this.isSignOutInProgress) return

    this.setSignOutRemoteErrors(undefined)
    this.setIsSignOutInProgress(true)

    logout()
      .then(({ data }) => {
        this.setIsAuthenticated(false)
        this.setUsername(undefined)
      })
      .catch(error => {
        // logout errors are not really anticipated
        this.setSignOutRemoteErrors(processApiError(error, adaptLogoutErrors))
      })
      .finally(() => {
        this.setIsSignOutInProgress(false)
      })
  }

  @action.bound
  resetPassword (data) {
    if (this.isResetPasswordInProgress) return

    this.setResetPasswordRemoteErrors(undefined)
    this.setIsResetPasswordInProgress(true)

    resetPassword(adaptResetPasswordRequest(data))
      .then(() => {
        this.setIsResetPasswordSuccessful(true)
      })
      .catch(error => {
        this.setResetPasswordRemoteErrors(processApiError(error, adaptResetPasswordErrors))
      })
      .finally(() => {
        this.setIsResetPasswordInProgress(false)
      })
  }

  /**
   * Return the state of the reset password operation to defaults
   */
  @action.bound
  resetResetPasswordState () {
    this.setIsResetPasswordInProgress(undefined)
    this.setResetPasswordRemoteErrors(undefined)
    this.setIsResetPasswordSuccessful(undefined)
  }

  @action.bound
  async signUp (data) {
    if (this.isSignUpInProgress) return

    this.setSignUpRemoteErrors(undefined)
    this.setIsSignUpInProgress(true)

    const payload = Object.assign({}, this.signUpDefaults, data)

    try {
      const response = await register(adaptRegisterRequest(payload))

      this.setUserHasProvidedCredentials(true)
      this.setIsAuthenticated(true)

      if (this.isIntegrateFlow) {
        await this.fetchUserData()
        this.setIsSignInInProgress(false)
        this.checkAuthorization()
      } else {
        const { redirectTo } = adaptRegisterResponse(response.data)
        const search = new URLSearchParams(window.location.search)
        window.location.href = search.get('next') || redirectTo || '/'
      }
    } catch (error) {
      this.setSignUpRemoteErrors(processApiError(error, adaptRegisterErrors))
      this.setIsSignUpInProgress(false)
    }
  }

  /**
   *  Return the state of the sign up operation to defaults
   */
  @action.bound
  resetSignUpState () {
    this.setIsSignUpInProgress(undefined)
    this.setSignUpRemoteErrors(undefined)
  }

  @action.bound
  async fetchUserData () {
    const [, getUserProfileResponse] = await Promise.all([
      this.root.appConfigStore.fetch(),
      getUserProfile(),
    ])
    this.root.initUserProfileStore(adaptUserProfile(getUserProfileResponse.data))
    return true
  }

  /**
   * Load app config and check if user is authenticated; set state accordingly.
   * If user is authenticated, follow up with authorization check.
   */
  @action.bound
  async checkAuthentication () {
    try {
      this.setStep(CONNECT_STEP_SIGNIN_CHECK_IN_PROGRESS)
      await this.fetchUserData()
      this.setIsAuthenticated(true)
      await this.checkAuthorization()
    } catch (error) {
      if (error.response?.status === 403) { // if not authenticated
        this.setIsAuthenticated(false)
        this.setStep(CONNECT_STEP_SIGNIN_REQUIRED)
      } else {
        throw error
      }
    }
  }

  @computed get pageTitle () {
    switch (this.step) {
      case CONNECT_STEP_WAIT_FOR_CONFIRMATION: return 'Confirm connection'
      default: return 'Connecting store'
    }
  }

  @computed get multistoresEnabled () {
    return this.storeIntegration.integrationProfileType === USER_TYPE_RETAILER
  }

  @action.bound async checkAuthorization () {
    if (this.storeIntegration.isAuthorizationCheckInProgress) return

    if (!this.isAuthenticated) {
      throw new Error('Cannot check authorization, user is not authenticated')
    }

    this.setStep(CONNECT_STEP_CHECK_IN_PROGRESS)

    const data = await this.storeIntegration.checkAuthorization()

    const { username, permissionsRequestUrl, ...checkResults } = data
    this.setUsername(username)

    logger.log(`Logged in as ${username}`)
    logger.log(`Manual confirmation is ${this.requireUserAuthorizationConfirmation ? '' : 'NOT '}required`)

    // Check for same store installation attempt, which can be a result of the user
    // clicking the App Link in Shopify with the intention to just go to Modalyst.
    // If that's the case, redirect them to Modalyst home for logged in users.
    // XXX: for multistores (SH-22) it may be a problem; needs clarification
    if (checkResults.installationExistsForUser === username) {
      this.setStep(CONNECT_STEP_REDIRECT_EXISTING_CONNECTION)
      window.location.href = '/'
      return data
    } else if (this.storeIntegration.authorizationPossible) {
      if (this.requireUserAuthorizationConfirmation) {
        // if authorization is possible, wait for user confirmation if required
        this.setStep(CONNECT_STEP_WAIT_FOR_CONFIRMATION)
      } else {
        // otherwise create authorization immediately
        await this.confirmAuthorization()
      }
    } else if (this.multistoresEnabled && this.storeIntegration.authorizationPossibleOnNewBusiness) {
      this.setStep(CONNECT_STEP_ADD_STORE_FORM)
    } else {
      this.setStep(CONNECT_STEP_IMPOSSIBLE)
    }
  }

  @action.bound async handleCreateStoreSubmit (data, params) {
    // XXX: this method assumes UserProfileStore is retailer's
    const { correlationId } = params
    const { name, country } = data
    const action = this.storeIntegration.integrationType
    this.root.biLoggerStore.log(addNewStoreModalNextClicked({
      origin: 'sign up', storeName: name, country, action, correlationId
    }))
    const businessData = await this.createStoreStore.submitFormData(data, params)
    this.setUserHasProvidedStoreDetails(true)
    this.storeIntegration.setBusiness(businessData.uuid)
    this.root.userProfileStore?.onBeforeStoreSwitch(businessData.id)
    return await this.confirmAuthorization()
  }

  /**
   * Proceed with creating the authorization.
   *
   * Can be invoked either automatically (if no user interaction is required) or
   * by the user clicking the respective button (see InstallIntegrationPage,
   * ConfirmConnection components).
   */
  @action.bound async confirmAuthorization () {
    if (this.storeIntegration.isConfirmAuthorizationInProgress) return

    const { authorizationPossible, confirmAuthorization } = this.storeIntegration

    const authorizationPossibleOnNewBusiness = (
      this.multistoresEnabled &&
      this.storeIntegration.authorizationPossibleOnNewBusiness
    )

    // critically fail if authorization is not possible in any scenario
    // (this action should not have been called)
    if (!authorizationPossible && !authorizationPossibleOnNewBusiness) {
      throw new Error(
        'Cannot proceed with creating the authorization ' +
        'because the check was either not called or indicated issues.'
      )
    }

    this.setStep(CONNECT_STEP_IN_PROGRESS)

    try {
      const { successRedirectUrl } = await confirmAuthorization(this.config.authorizationData)
      this.setStep(CONNECT_STEP_CREATED)
      window.location.assign(
        successRedirectUrl ||
        (new URLSearchParams(window.location.search)).get('next') ||
        '/'
      )
    } catch (error) {
      this.setStep(CONNECT_STEP_FAILED)
    }
  }
}

export default AuthStore
