import React from 'react'

import { Button } from '@material-ui/core'
import { observer } from 'mobx-react'

import { Alert } from 'shared/components/molecules'
import { StoreIntegrationCheckWarning } from 'shared/components/organisms'
import { useRemoteErrors } from 'shared/utils/forms'

import { SwitchAccount } from 'signup/components/organisms'
import { useAppConfig } from 'signup/config/hooks'
import { CONNECT_STEP_SIGNIN_REQUIRED } from 'signup/constants'
import { useStores } from 'signup/stores/hooks'

import style from './AuthorizationCheckWarning.module.scss'

const IntegrationsLink = () => {
  const { integrationsUrl } = useAppConfig()
  return integrationsUrl
    ? <Button variant="outlined" href={integrationsUrl}>Manage integrations</Button>
    : null
}

/**
 * Show issues blocking the connection between a Modalyst business and a specific site
 *
 * The component renders a message describing an issue with possible additional steps according to
 * a predefined order. May additionally show an alert containing the errors from the check request itself.
 */
const AuthorizationCheckWarning = () => {
  const { authStore } = useStores()
  const { signOut, storeIntegration } = authStore
  const { authorizationCheckResults, integrationType, authorizationCheckRemoteErrors } = storeIntegration

  const { allErrors } = useRemoteErrors(authorizationCheckRemoteErrors)

  return (
    <div className={style.AuthorizationCheckWarning}>
      {allErrors && (
        <Alert severity="error" className={style.RemoteErrors}>
          {allErrors.join(' ')}
        </Alert>
      )}

      {authorizationCheckResults && (
        <StoreIntegrationCheckWarning
          results={authorizationCheckResults}
          integrationType={integrationType}
          switchAccount={<SwitchAccount />}
          createNewAccount={
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                signOut()
                authStore.setStep(CONNECT_STEP_SIGNIN_REQUIRED)
              }}
            >
              Create new account
            </Button>
          }
          manageIntegrations={<IntegrationsLink />}
          signOutFn={signOut}
        />
      )}
    </div>
  )
}

export default observer(AuthorizationCheckWarning)
