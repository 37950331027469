import React, { useEffect } from 'react'

import PropTypes from 'prop-types'

import { Button } from '@material-ui/core'
import { observer } from 'mobx-react'
import { Link as RouterLink } from 'react-router-dom'

import { SignInForm } from 'signup/components/organisms'
import { SignUpTemplate } from 'signup/components/templates'
import { PATH_SIGNUP, PATH_RESET_PASSWORD } from 'signup/constants'

const SignInPage = ({ location, store }) => {
  const {
    signIn,
    isAuthInProgress,
    isSignInInProgress,
    signInRemoteErrors,
    resetSignInState,
  } = store

  useEffect(function () {
    return resetSignInState
  }, [])

  // copy search string so we can maintain any GET params passed to the view
  const signUpLocation = { pathname: PATH_SIGNUP, search: location.search }
  const resetPasswordLocation = { pathname: PATH_RESET_PASSWORD, search: location.search }

  return (
    <SignUpTemplate
      title="Sign In"
      headerNav={
        <Button
          component={RouterLink}
          to={signUpLocation}
          variant="outlined"
          color="primary"
        >
          Create an account
        </Button>
      }
    >
      <SignInForm
        onSubmit={signIn}
        disabled={!!isAuthInProgress}
        showSpinner={!!isSignInInProgress}
        remoteErrors={signInRemoteErrors}
        resetPasswordLocation={resetPasswordLocation}
        signUpLocation={signUpLocation}
      />
    </SignUpTemplate>
  )
}

SignInPage.propTypes = {
  location: PropTypes.object.isRequired,
  store: PropTypes.shape({
    signIn: PropTypes.func.isRequired,
    isAuthInProgress: PropTypes.bool,
    isSignInInProgress: PropTypes.bool,
    signInRemoteErrors: PropTypes.object,
    resetSignInState: PropTypes.func.isRequired,
  }).isRequired,
}

export default observer(SignInPage)
