import React from 'react'

import PropTypes from 'prop-types'

import { Box, Typography } from '@material-ui/core'
import clsx from 'clsx'

import style from './FormHeader.module.scss'

const FormHeader = ({ className, title, subTitle, color, ...boxProps }) => {
  return (
    <Box component="header" className={clsx(style.FormHeader, className)} {...boxProps}>
      <Typography variant="h4" gutterBottom color={color}>
        {title}
      </Typography>
      <Typography variant="subtitle2" color="textSecondary">
        {subTitle}
      </Typography>
    </Box>
  )
}

FormHeader.propTypes = {
  ...Box.propTypes,
  className: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  subTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
}

FormHeader.defaultProps = {
  color: 'textPrimary'
}

export default FormHeader
