/* eslint camelcase: 0 */
/** Default MUI theme for the Modalyst Web UI */

import { alpha, unstable_createMuiStrictModeTheme } from '@material-ui/core/styles'

import { muiTheme as baseTheme } from 'shared/theme'

const { palette, typography, overrides, breakpoints } = baseTheme

const muiTheme = unstable_createMuiStrictModeTheme({
  themeName: 'Modalyst Signup Theme',
  direction: 'ltr', // RTL option
  palette,
  typography: {
    ...typography,
    h5: {
      fontSize: '20px',
      fontWeight: 700
    },
  },
  breakpoints,
  overrides: {
    ...overrides,
    MuiCard: {
      root: {
        outline: `1px solid ${alpha(palette.grey[200], 0.5)}`,
      }
    },
    MuiCardHeader: {
      root: {
        borderBottom: `1px solid ${palette.grey[100]}`,
      }
    },
    MuiCardActions: {
      root: {
        justifyContent: 'center',
        borderTop: `1px solid ${palette.grey[100]}`,
      }
    }
  },
})

export default muiTheme
